@import '../../styles/partials/variables.module.scss';

.createOptionsContainer {
  align-items: center;
  display: flex;
  justify-content: center;
  width: 100%;
}

.createOptionContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.modalActions2 {
  justify-content: right;
  position: relative;
  & .backModalBtn {
    position: absolute;
    left: 0;
  }
}

.dropFiles {
  align-items: center;
  border: 1px dashed $paragraph-baseline;
  border-radius: 8px;
  color: $paragraph-baseline;
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  font-weight: 400;
  font-size: 14px;
  height: 9.5rem;
  letter-spacing: 0.002em;
  line-height: 24px;
  padding: 0.25rem;
  text-align: center;
  width: 100;
}

.selectedFile {
  align-items: center;
  border-bottom: 1px solid $disabled-gray-1;
  color: $paragraph-baseline;
  display: flex;
  font-size: 1rem;
  font-weight: 400;
  justify-content: space-between;
  height: 3.75rem;
  letter-spacing: 0.004em;
  line-height: 1.5rem;
  padding: 0 1.5rem;
  text-align: justify;
}

.selectedFileLabel {
  align-items: center;
  display: flex;
}

.backLink {
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  text-transform: none;
  color: $primary-blue;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  .MuiOutlinedInput-root {
    border-radius: 8px;
  }
}

.dragFileElement {
  position: absolute;
  height: 29%;
  border-radius: 8px;
  margin: 11rem 5.5rem;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}
