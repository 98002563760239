@import '../../styles/partials/variables.module.scss';

.side_main_menu {
  position: fixed;
  left: 0;
  width: 244px;
  height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;

  /* Background White */
  background: $background-white;

  /* Hierarchy 2 */
  box-shadow: 0px 5px 10px rgba(29, 63, 156, 0.2);
}

.frame {
  box-sizing: border-box;
  min-height: 100%;
  width: 250px;
  padding: 6rem 1.5rem 4rem 2.5rem;
}

.title {
  /* Sub2 */
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 1.5rem;
  /* or 171% */
  height: 2.88461538462%;

  display: flex;
  align-items: center;
  letter-spacing: 0.01em;
  text-transform: uppercase;

  /* Paragraph - Paragraph Baseline */
  color: $paragraph-baseline;
}
