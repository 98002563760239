@import '../../styles/partials/variables.module.scss';

.viewTitle {
  margin-bottom: 0.5rem;
  font-weight: 600;
  font-size: 1.5rem;
  line-height: 2rem;
  text-align: left;
}

.viewDescription {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  margin: 0 0 4rem;
  /* identical to box height, or 150% */

  letter-spacing: 0.004em;
  color: $paragraph-placeholder;
}

.editContainer {
  display: flex;
  margin-top: 3rem;
  margin-right: 16rem;
}

.mainContainer {
  align-items: left;
  display: flex;
  flex-direction: column;
  margin-bottom: 4rem;
  text-align: left;
  max-width: 77rem;
  width: 100%;
}

aside {
  width: 15.25rem;
  display: flex;
  justify-content: right;
  align-items: flex-start;
  margin: 1.5rem 2.5rem !important;
}

.info {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  display: flex;
  align-items: center;
  color: $paragraph-baseline;
}

.actionBtn {
  background-color: $primary-blue;
  border: none;
  border-radius: 4px;
  color: $background-white;
  font-size: 1rem;
  font-weight: 600;
  height: 2.5rem;
  letter-spacing: 0.01em;
  margin-left: 1rem;
  padding: 0.5rem 1rem;
  text-transform: none;
  width: 5.8125rem;
  &:hover {
    cursor: pointer;
    background-color: $primary-hover-blue-2;
  }
  &:focus-visible {
    outline: 2px solid #b770fe;
  }
  &:active {
    background-color: $primary-pressed-blue;
  }
  &:disabled {
    color: $disabled-gray-2;
    cursor: unset;
    background-color: $disabled-gray-1;
  }
}
