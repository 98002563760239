@import '../../../styles/partials/variables.module.scss';

g {
  opacity: 0;
  animation: spinner 2.1s ease; // Must be 2.1
  animation-iteration-count: 100;
}

svg.spinner {
  margin-bottom: 1rem;
  #group1 {
    animation-delay: 0.25s;
  }
  #group2 {
    animation-delay: 0.5s;
  }
  #group3 {
    animation-delay: 0.75s;
  }
  #group4 {
    animation-delay: 1s;
  }
  #group5 {
    animation-delay: 1.25s;
  }
  #group6 {
    animation-delay: 1.5s;
  }
  #group7 {
    animation-delay: 1.75s;
  }
  #group8 {
    animation-delay: 2s;
  }
}

@keyframes spinner {
  0% {
    opacity: 1;
  }
  12% {
    opacity: 1;
  }
  25% {
    opacity: 0;
  }
}
