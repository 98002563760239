@import '../../styles/partials/variables.module.scss';

.viewHeader {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.subHeader {
  color: $paragraph-placeholder;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.004em;
  line-height: 24px;
  margin: 0;
  padding-top: 0.5rem;
  width: 40vw;
}

.viewTitle {
  font-weight: 600;
  font-size: 1.5rem;
  line-height: 2rem;
  text-align: left;
  color: $paragraph-baseline;
}

.mainContainer {
  min-height: 80vh;
  margin-right: 15.25rem;
  display: flex;
  flex-direction: column;
  align-items: left;
  width: 75%;
}

.editContainer {
  display: flex;
  margin-top: 6rem;
  height: 100vh;
}

aside {
  width: 15.25rem;
  height: 80vh;
  display: flex;
  justify-content: right;
  align-items: flex-start;
  margin: 1.5rem 2.5rem;
}

.backLink {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: $primary-blue;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
}
