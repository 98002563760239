/* Temporarily setting the !important since moving several text parts into mui's typography component, was overriding these styles */
* {
  font-family: 'Rubik', sans-serif !important;
}

*:no-button {
  color: #474748 !important;
}

.App {
  text-align: center;
}
