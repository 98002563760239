@import '../../styles/partials/variables.module.scss';

aside.left {
  align-items: flex-start;
  display: flex;
  height: 120vh;
  justify-content: right;
  width: 15rem;
}

.mainContainer {
  align-items: left;
  display: flex;
  flex-direction: column;
  margin: 0 2rem;
  max-width: 792px;
  min-height: 120vh;
  min-width: 792px;
  header {
    text-align: left;
  }
  .chipsBox {
    margin-top: 1.5rem;
  }
  .canvasBox {
    border: 1px solid $paragraph-baseline;
    border-radius: 8px;
    margin-top: 24px;
  }
  .canvas {
    align-items: center;
    background-color: $background-white;
    border-radius: 8px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    height: 424px;
    justify-content: center;
    max-width: 100%;
    overflow: hidden;
    position: relative;
    .colorPicker {
      display: flex;
      flex-direction: column;
      left: 0.2rem;
      position: absolute;
      top: 0.2rem;
      width: fit-content;
      z-index: 100;
    }
    .colors {
      border: 1px solid #b5b5b6;
      border-radius: 50%;
      height: 25px;
      margin: 0.25rem 0.25rem 0;
      width: 25px;
    }
  }
  .formBox {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 2rem 0;
  }
  footer {
    align-self: flex-end;
    display: flex;
    margin: 1rem 0 4rem;
  }
}
