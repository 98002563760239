@import '../../styles/partials/variables.module.scss';

.iconContainer {
  display: inline-flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  width: 1rem;
  height: 1rem;
}

.icon {
  max-width: 100%;
  max-height: 100%;

  path {
    fill: $paragraph-baseline;
    transition: fill $subtle-transition;
  }

  &.disabled path {
    fill: $disabled-gray-1;
  }

  &.selected path {
    fill: $primary-blue;
  }
}
