@import '../../styles/partials/variables.module.scss';

.colorPickerPaper {
  width: 318px;
  height: 383px;
  border-radius: 8px;
  padding: 18px;
  margin-top: 8px;
}

.colorPickerRgbInput::-webkit-outer-spin-button, .colorPickerRgbInput::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.colorPickerRgbInput[type=number]{
  appearance: inherit;
  -moz-appearance: textfield;
}