@import '../../styles/partials/variables.module.scss';

.subHeader {
  color: $paragraph-placeholder;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.004em;
  line-height: 24px;
  margin: 0;
  padding-top: 0.5rem;
  width: 40vw;
}

hr {
  border: 0.5px solid #d8d8d9;
  margin: 2.5rem 0 1.7rem;
}
