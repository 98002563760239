@import '../../styles/partials/variables.module.scss';

.H2 {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 600;
  font-size: 1.5rem;
  line-height: 2rem;
  /* identical to box height, or 133% */

  /* Paragraph - Paragraph Baseline */
  color: $paragraph-baseline;

  margin-bottom: 1.5rem;
  margin-top: 1.5rem;
  display: inline-block;
}

.H3 {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.5rem;
  /* identical to box height, or 150% */
  display: flex;
  letter-spacing: 0.01em;

  /* Paragraph - Paragraph Baseline */
  color: $paragraph-baseline;
}

.P1 {
  /* Paragraph 1 (baseline) Baseline */
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5rem;
  /* identical to box height, or 150% */
  display: flex;
  align-items: center;
  letter-spacing: 0.002em;

  /* Paragraph - Paragraph Baseline */
  color: $paragraph-baseline;

  margin: 0;
}

.P2 {
  /* Paragraph 2 */
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 1.5rem;
  /* or 171% */
  display: flex;
  align-items: center;
  letter-spacing: 0.002em;

  /* Paragraph - Paragraph Baseline */
  color: $paragraph-baseline;
}

.subHeader {
  color: $paragraph-placeholder;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.004em;
  line-height: 24px;
  margin: 0;
  padding-top: 0.5rem;
  width: 40vw;
}

.deleteMemberBtn {
  all: unset;
  color: $primary-blue;
  cursor: pointer;
}

.pointer {
  cursor: pointer;
}
